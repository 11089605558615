import NB from "./NB"
import {TwentyFourHLineChart, SevenDaysLineChart} from './LineChart';
import Cards from './cards.js';
import './globals.css';

export default function MainPage(props) {
    return <div className={"MainPage"}>
        <NB signin={false}/>
        <div className="App">
            <br/>
            <h1 id={"last"} className={"text"} style={{margin: "1em", marginTop: "2em"}}>Senaste mätning</h1>
            <Cards loginCreds={props.loginCreds}/>
            <div style={{margin: "1.5em", marginTop: "6em"}}>
                <h1 id={"24h"} className={"text"} style={{margin: "1.5em"}}>Senaste dygnet</h1>
                <TwentyFourHLineChart loginCreds={props.loginCreds}/>
            </div>
            <div style={{margin: "1.5em", marginTop: "6em"}}>
                <h1 id={"7d"} className={"text"} style={{margin: "1.5em"}}>Senaste veckan</h1>
                <SevenDaysLineChart loginCreds={props.loginCreds}/>
            </div>
            <br/> {/* For some reason this prevents a white rectangle to be shown at the bottom*/}
        </div>
    </div>
}
import React, {useState, useEffect} from "react"
import {Card} from 'react-bootstrap';
import './cards.css';


const protocol = window.location.protocol
const hostname = window.location.host;
const baseUrl = `${protocol}//${hostname}/sql`

const Cards = (props) => {
    const [chart, setChart] = useState([])

    useEffect(() => {
        const f = async () => {
            await fetch(`${baseUrl}?get_last=true&username=${props.loginCreds.username}&password=${props.loginCreds.password}`,

                {method: "GET"})
                .then(response => response.json())
                .then(json => {
                    setChart(json)
                })
        }
        f()
        setInterval(f, 15_000); // Refresh every 30 seconds
    }, [baseUrl])

    const [isDarkMode, setIsDarkMode] = useState(
        window.matchMedia('(prefers-color-scheme: dark)').matches
    );

    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

        const handleMediaQueryChange = () => {
            setIsDarkMode(mediaQuery.matches);
        };

        // Add listener on mount
        mediaQuery.addListener(handleMediaQueryChange);

        // Remove listener on unmount
        return () => {
            mediaQuery.addListener(handleMediaQueryChange);
        };
    }, []);


    return (
        <div className={"flexDiv"}>
            <Card border={"danger"} bg={isDarkMode ? 'dark' : 'light'} text={isDarkMode ? 'white' : 'dark'} style={{width: '15rem'}}>
                <Card.Header>Temperatur</Card.Header>
                <Card.Body as="h1">{`${chart?.celsius} \u2103`}</Card.Body>
                <Card.Footer>Mättes {chart?.created_at}</Card.Footer>
            </Card>
            <Card border={"primary"} bg={isDarkMode ? 'dark' : 'light'} text={isDarkMode ? 'white' : 'dark'} style={{width: '15rem'}}>
                <Card.Header className={"card-header"}>Luftfuktighet</Card.Header>
                <Card.Body as="h1" className={"card-body"}>{chart?.humidity} %</Card.Body>
                <Card.Footer className={"card-footer"}>Mättes {chart?.created_at}</Card.Footer>
            </Card>
        </div>
    );
}

export default Cards;

import React, {useState, useEffect} from "react"
import {Line} from "react-chartjs-2"
import {
    Chart as ChartJJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Legend,
    Tooltip,
    Filler
} from "chart.js"

const protocol = window.location.protocol
const hostname = window.location.host;
const baseUrl = `${protocol}//${hostname}/sql`

ChartJJS.register(CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Legend,
    Tooltip,
    Filler
);

function getSQLDate(minutesBackFromNow) {
    const earlier = Date.now() - (60 * 1000 * minutesBackFromNow);
    return new Date(earlier).toISOString().slice(0, 16);
}

function TwentyFourHLineChart(props) {
    var [chart, setChart] = useState([])

    var count = 1
    useEffect(() => {
        const f = async () => {
            await fetch(`${baseUrl}?since-date=${getSQLDate(60 * 22 - 5)}&username=${props.loginCreds.username}&password=${props.loginCreds.password}`,
                {method: "GET"})
                .then(response => response.json())
                .then(json => {
                    setChart(json)
                })
        }
        f()
        setInterval(f, 15_000); // Refresh every 15 seconds 
    }, [baseUrl])

    var data = {
        datasets: [
            {
                label: "Temperatur (\u2103)",
                data: chart?.map(a => {
                    return {y: a.celsius, x: dateToClock(new Date(a.created_at))}
                }),
                borderWidth: 1,
                borderColor: '#AA4A44',
                backgroundColor: '#EC5800',
            },
            {
                label: "Luftfuktighet (%)",
                data: chart?.map(a => {
                    return {y: a.humidity, x: dateToClock(new Date(a.created_at))}
                }),
                borderWidth: 1,
                borderColor: '#36A2EB',
                backgroundColor: '#000080',
            }]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {position: "top"},
            title: {display: true, text: `Temperatur ("\u2103")`}
        },
        scales: {
            y: {
                beginAtZero: false
            }
        },
        legend: {
            labels: {
                fontSize: 14
            }
        }
    }

    return (
        <div>
            <Line data={data} options={options} height={600}/>
        </div>
    )
}

function SevenDaysLineChart(props) {
    const [chart, setChart] = useState([])

    useEffect(() => {
        const f = async () => {
            await fetch(`${baseUrl}?since-date=${getSQLDate(24 * 60 * 7)}&username=${props.loginCreds.username}&password=${props.loginCreds.password}`,
                {method: "GET"})
                .then(response => response.json())
                .then(json => {
                    setChart(json)
                })
        }
        f()
        setInterval(f, 15_000); // Refresh every 15 seconds 
    }, [baseUrl])

    var data = {
        datasets: [
            {
                label: "Temperatur (\u2103)",
                data: chart?.map(a => {
                    const date = new Date(a.created_at);
                    const dateStr = dateToDay(date) + " " + dateToClock(date);

                    return {y: a.celsius, x: dateStr}
                }),
                borderWidth: 1,
                borderColor: '#AA4A44',
                backgroundColor: '#EC5800',
            },
            {
                label: "Luftfuktighet (%)",
                data: chart?.map(a => {
                    const date = new Date(a.created_at);
                    const dateStr = dateToDay(date) + " " + dateToClock(date);

                    return {y: a.humidity, x: dateStr}
                }),
                borderWidth: 1,
                borderColor: '#36A2EB',
                backgroundColor: '#000080',
            }]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {position: "top"},
            title: {display: true, text: "Temperatur"}
        },
        scales: {
            y: {
                beginAtZero: false
            }
        },
        legend: {
            labels: {
                fontSize: 14
            }
        }
    }

    return (
        <div>
            <Line data={data} options={options} height={600}/>
        </div>
    )
}

function dateToClock(date) {
    const [hours, minutes] = [date.getHours(), date.getMinutes()];
    return `${addZeros(hours)}:${addZeros(minutes)}`;
}

function dateToDay(date) {
    const [month, day] = [date.getMonth() + 1, date.getDate()];
    return `${addZeros(month)}-${addZeros(day)}`;
}

function addZeros(number) {
    let s = "" + number;
    if (number < 10) s = 0 + s;
    return s;
}

export {TwentyFourHLineChart, SevenDaysLineChart}

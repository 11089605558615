import './App.css';
import './cards.css'
import React from 'react';
import LoginPage from './LoginPage'; // Import the login page component
import MainPage from './MainPage';
import Cookies from 'js-cookie';

export default class App extends React.Component {
    state = this.checkCookie()

    checkCookie() {
        const [username, password] = [Cookies.get("username"), Cookies.get("password")];

        if (username && password) {
            return {
                isLoggedIn: true,
                username: username,
                password: password,
            }
        } else {
            return {
                isLoggedIn: false,
                username: "",
                password: "",
            }
        }


    }

    // Method to update the login state
    updateLoginState = (isLoggedIn, username, password) => {
        this.setState({isLoggedIn, username, password});

        if (isLoggedIn) {
            Cookies.set("username", username, {expires: 7, secure: true});
            Cookies.set("password", password, {expires: 7, secure: true});
        }
    }

    render() {
        return (
            <>
                {/* Check if the user is logged in */}
                {this.state.isLoggedIn ? (
                    // If the user is logged in, render the existing content
                    <React.Fragment>
                        <MainPage loginCreds={this.state}/>
                    </React.Fragment>
                ) : (
                    // If the user is not logged in, render the login page
                    <LoginPage updateLoginState={this.updateLoginState}/>
                )}
            </>
        );
    }
}
